import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import {
  requestNextApiEndpoint,
  SHOPIFY_CUSTOMERS_UPDATE_LOCALE_PARAMS,
} from '~/lib/handle-api'

import useLang from '~/hooks/useLang'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useShopifyUpdateCustomerLocale(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const lang = useLang()

  return useMutation<any, any, any>(
    async ({ id }) => {
      return await requestNextApiEndpoint(
        SHOPIFY_CUSTOMERS_UPDATE_LOCALE_PARAMS,
        {
          payload: {
            id,
            locale: lang,
          },
        },
      )
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        if (mutationOptions?.onSuccess) mutationOptions.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        console.log(err)
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
