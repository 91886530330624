import classnames from 'classnames/bind'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useGoogleReCaptcha } from '~/providers/RecaptchaProvider/use-google-recaptcha'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type RecaptchaHandlerProps = {
  className?: string
  children?: JSX.Element | JSX.Element[]
}

function RecaptchaHandler({ className, children }: RecaptchaHandlerProps) {
  const [inViewRef, inView] = useInView({ initialInView: false })
  const { loadRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (inView) loadRecaptcha()
  }, [inView])

  return (
    <div ref={inViewRef} className={cx(css.RecaptchaHandler, className)}>
      <div className={css.marker} />
      {children}
    </div>
  )
}

RecaptchaHandler.defaultProps = {}

export default RecaptchaHandler
