import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { ALERT_CODES } from '~/@types/alert'
import {
  requestNextApiEndpoint,
  SHOPIFY_CUSTOMERS_NEWSLETTER_PARAMS,
} from '~/lib/handle-api'

import useShopifyUpdateCustomerLocale from '~/hooks/account/useShopifyUpdateCustomerLocale'
import useAlerts from '~/hooks/useAlerts'
import useLang from '~/hooks/useLang'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useShopifyNewsletterMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const triggerAlert = useAlerts()
  const lang = useLang()

  const { mutate: mutateCustomerLocale } = useShopifyUpdateCustomerLocale({})

  return useMutation<any, any, any>(
    async ({ email, tags, acceptsMarketing = true }) => {
      return await requestNextApiEndpoint(SHOPIFY_CUSTOMERS_NEWSLETTER_PARAMS, {
        payload: {
          email,
          lang,
          tags,
          acceptsMarketing,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        triggerAlert(ALERT_CODES.NEWSLETTER_SUBSCRIBE)
        mutationOptions?.onSuccess(data, ...rest)
        mutateCustomerLocale({ lang, id: data?.id })
      },
      onError: (err, ...rest) => {
        console.log(err)
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
