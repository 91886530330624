type TagsProps = {
  currency?: string
  from?: string
  country?: string
}

export default function getTags({ currency, from, country }: TagsProps) {
  return [
    `currency:${currency}`,
    from ? `from:${from}` : null,
    country ? `country:${country?.toUpperCase()}` : null,
  ]
    ?.filter((item) => item)
    ?.join(',')
}
