import classnames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalInputPreset } from '~/@types/input-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'
import getTags from '~/lib/shopify-tags'

import Input, { InputProps } from '~/components/Abstracts/Form/Input'
import { useInputPreset } from '~/components/Abstracts/Form/Input/hooks'
import Spinner from '~/components/Abstracts/Spinner'
import Checkbox from '~/components/Form/Checkbox'
import ErrorList from '~/components/Form/ErrorList'
import RecaptchaHandler from '~/components/Form/RecaptchaHandler'
import { ArrowRightIcon } from '~/components/UI/Icons'
import RichText, { RichTextBlock } from '~/components/UI/RichText'
import SquaredCta from '~/components/UI/SquaredCta'

import { useGeolocation } from '~/providers/GeolocationProvider'
import { useStyle } from '~/providers/StyleProvider'
import { useTheme } from '~/providers/ThemeProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useShopifyNewsletterMutation from '~/hooks/account/useShopifyNewsletterMutation'
import useCurrency from '~/hooks/useCurrency'
import useFormWithMutation from '~/hooks/useFormWithMutation'

import { FORM } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type NewsletterTheme = GlobalThemeColors

export type NewsletterProps = InputProps & {
  className?: string
  formClassName?: string
  inputClassName?: string
  errorsClassName?: string
  resultClassName?: string
  gdprClassName?: string
  inputAndGDPRWrapperClassName?: string
  textSubmit?: boolean
  fromTag?: string
  uniqueId?: string
  isInline?: boolean
  theme?: NewsletterTheme
  footer?: JSX.Element | JSX.Element[]
  gdprLabel?: RichTextBlock
  gdprText?: RichTextBlock
  onSuccess?(): void
}

type GDPRLabelsProps = Partial<NewsletterProps> & {
  gdprVisible?: boolean
  gdprStyle?: string
  componentColor?: GlobalThemeColors
}

function GDPRLabels({
  gdprClassName,
  gdprVisible,
  gdprLabel,
  gdprText,
  gdprStyle,
  uniqueId,
  componentColor,
}: GDPRLabelsProps) {
  return (
    <>
      {gdprLabel && gdprVisible && (
        <Checkbox
          id={`gdpr-${uniqueId}`}
          name={`gdpr-${uniqueId}`}
          required={true}
          isRound
          className={cx(css.gdpr, gdprStyle, gdprClassName, {
            visible: gdprVisible,
          })}
          theme={componentColor}
          label={<RichText render={gdprLabel} />}
        />
      )}
      {gdprText && gdprVisible && (
        <RichText
          className={cx(css.gdprText, gdprStyle, gdprClassName, {
            visible: gdprVisible,
          })}
          render={gdprText}
        />
      )}
    </>
  )
}

function Newsletter({
  className,
  formClassName,
  inputClassName,
  inputAndGDPRWrapperClassName,
  textSubmit,
  errorsClassName,
  resultClassName,
  children,
  placeholder,
  gdprLabel,
  gdprText,
  gdprClassName,
  onSuccess,
  fromTag,
  theme,
  uniqueId,
  isInline,
  ...rest
}: NewsletterProps) {
  const t = useTranslate()
  const { countryISO } = useGeolocation()
  const [gdprVisible, setGdprVisible] = useState(false)
  const currency = useCurrency()
  const props = useInputPreset(GlobalInputPreset.Email, {
    placeholder: placeholder || t(FORM.NEWSLETTER_EMAIL),
    ...rest,
  })

  const componentColor = theme ?? GlobalThemeColors.Black
  const { colors } = useTheme()

  const inputStyle = useStyle({
    color: componentColor,
    textPreset: GlobalTextPreset.Cta12Grotesk,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const borderStyle = useStyle({
    borderColor: componentColor,
  })

  const textStyle = useStyle({
    color: componentColor,
    textPreset: isInline
      ? GlobalTextPreset.Cta12Grotesk
      : GlobalTextPreset.Cta12GroteskRegular,
  })

  const submitStyle = useStyle({
    borderColor: componentColor,
    textPreset: GlobalTextPreset.Cta12Grotesk,
    color: componentColor,
  })

  const gdprStyle = useStyle({
    color: componentColor,
    textPreset: GlobalTextPreset.Cta12Grotesk,
  })

  const [result, setResult] = useState(false)

  const tracker = useTracker()

  const { mutate: newsletterMutation, isLoading } =
    useShopifyNewsletterMutation(
      {
        onSuccess: () => {
          tracker.emit(TRACKING_EVENTS.NEWSLETTER_SUBSCRIPTION, {
            origin: fromTag,
          })
          setResult(t(FORM.NEWSLETTER_SUCCESS))
          onSuccess?.()
        },
      },
      (...params) => form.setError(...params),
    )

  const { form, onSubmit } = useFormWithMutation(
    newsletterMutation,
    null,
    'newsletter',
  )

  useEffect(() => {
    if (
      form?.formState?.errors &&
      Object.keys(form.formState.errors).length > 0
    ) {
      setResult(false)
    }
  }, [form.formState])

  useEffect(() => {
    form.reset({
      tags: getTags({
        currency,
        from: fromTag,
        country: countryISO,
      }),
    })
  }, [currency, countryISO, fromTag, form])

  const tags = getTags({
    currency,
    from: fromTag,
    country: countryISO,
  })

  return (
    <div
      className={cx(
        css.newsletter,
        className,
        css?.[`${componentColor}Theme`],
        { isNotInline: !isInline },
      )}>
      <RecaptchaHandler>
        <FormProvider {...form}>
          <form className={cx(formClassName, css.form)} onSubmit={onSubmit}>
            <div className={cx(css.input, borderStyle, inputClassName)}>
              <Input
                className={cx(textStyle, inputAndGDPRWrapperClassName)}
                onFocus={() => setGdprVisible(true)}
                onChange={() => setResult(false)}
                {...props}
              />

              {!result && (
                <ErrorList
                  className={cx(css.errorList, errorsClassName)}
                  name={props?.name}
                />
              )}

              {!isInline && (
                <GDPRLabels
                  gdprClassName={cx(
                    gdprClassName,
                    inputAndGDPRWrapperClassName,
                  )}
                  gdprVisible={gdprVisible}
                  gdprLabel={gdprLabel}
                  gdprText={gdprText}
                  gdprStyle={gdprStyle}
                  uniqueId={uniqueId}
                  componentColor={componentColor}
                />
              )}

              <div className={css.submitButton}>
                {isLoading ? (
                  <Spinner
                    className={cx(css.spinner)}
                    color={colors?.[componentColor]}
                  />
                ) : isInline ? (
                  <button
                    className={cx(css.submit, submitStyle, { textSubmit })}
                    type="submit"
                    {...(!textSubmit && { 'aria-label': 'newsletter-submit' })}>
                    {!textSubmit && <ArrowRightIcon className={cx(css.icon)} />}
                    {textSubmit && t(FORM.SUBMIT)}
                  </button>
                ) : (
                  <SquaredCta
                    className={cx(css.submit, submitStyle, { textSubmit })}
                    type="submit"
                    theme={componentColor}
                    withBackground>
                    {!textSubmit && <ArrowRightIcon className={cx(css.icon)} />}
                    {textSubmit && t(FORM.SUBMIT)}
                  </SquaredCta>
                )}
              </div>
            </div>

            <Input type="hidden" name="tags" value={tags} />

            {isInline && (
              <GDPRLabels
                gdprClassName={gdprClassName}
                gdprVisible={gdprVisible}
                gdprLabel={gdprLabel}
                gdprText={gdprText}
                gdprStyle={gdprStyle}
                uniqueId={uniqueId}
                componentColor={componentColor}
              />
            )}

            {result && (
              <div className={cx(resultClassName, css.result, inputStyle)}>
                {result}
              </div>
            )}
          </form>
        </FormProvider>
      </RecaptchaHandler>
    </div>
  )
}

Newsletter.defaultProps = {
  textSubmit: false,
  isInline: true,
}

export default Newsletter
